<!-- 编辑文档 -->
<template>
  <el-dialog
      title="编辑文档"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="30%">
    <el-form ref="form" :model="form" label-width="80px" size="small" :rules="rules">
      <el-form-item label="文档名称" prop="title">
        <el-input v-model="form.title" placeholder="请输入文档名称"></el-input>
      </el-form-item>
      <!--      <el-form-item label="文档内容" prop="content">-->
      <!--        <el-input v-model="form.content" type="textarea" placeholder="请输入文档内容"></el-input>-->
      <!--      </el-form-item>-->
      <el-form-item label="文档内容" prop="content">
        <el-upload
            class="w-36 border-gray-400 border-dashed border text-center"
            action="#"
            accept="application/pdf"
            :show-file-list="false"
            :http-request="logoUploader"
        >
              <span v-if="form.content" class="text-xs"
              >文档已上传,点击此处更换</span
              >
          <i
              v-else
              class="el-icon-plus text-xl justify-content-center align-items-center flex"
          ></i>
        </el-upload>
        <a
            v-if="form.content"
            class="text-xs"
            :href="form.content"
            target="_blank"
        >点击查看文件</a
        >
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less">
</style>
<script type="text/ecmascript-6">
import oss from '@utils/oss'

export default {
  data () {
    return {
      dialogVisible: false,
      form: {
        title: '',
        content: ''
      },
      rules: {
        title: [
          {
            required: true,
            message: '请输入文档名称',
            trigger: 'blur'
          }
        ],
        content: [
          {
            message: '请输入文档内容',
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    initData () {
      this.form = {
        title: '',
        content: ''
      }
    },
    close () {
      this.initData()
      this.dialogVisible = false
    },
    show () {
      this.dialogVisible = true
    },
    edit (item) {
      this.form = item
      this.show()
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.title === '') {
            this.$message.error('请输入文档名称')
            return false
          }
          this.$api.save('helpDoc', this.form).then(() => {
            this.$message.success('保存成功')
            this.$emit('on-submit')
            this.close()
          })
        }
      })
    },
    async logoUploader (item) {
      const { file } = item
      if (this.isPDFFile(file)) {
        this.form.content = await oss.upload(file)
      } else {
        this.$message.error('仅支持PDF文件')
      }
    },
    isPDFFile (file) {
      const regex = /^application\/(pdf)$/i
      return regex.test(file.type)
    }
  },
  mounted () {
  }
}
</script>
