<!-- 帮助列表 -->
<template>
  <div>
    <blockTitle title="帮助列表"></blockTitle>
    <el-card shadow="never">
      <div class="card-content">
        <AppTable
            ref="AppTable"
            controller="helpDoc"
            :tableColumns="tableColumns"
            @on-handleSelection-change="onHandleSelectionChange"
        >
          <template #operator="{ row }">
            <el-button size="small" @click="editHelpDoc(row)"
            >编辑
            </el-button>
            <el-button size="small" @click="showHelpDoc(row)"
            >查看文档
            </el-button>
          </template>
          <template #quick-operator-box>
            <el-button
                class="ml-4"
                type="primary"
                size="mini"
                @click="createHelpDoc"
            >创建文档
            </el-button>
            <el-button
                v-if="multipleSelection.length > 0"
                type="danger"
                size="mini"
                @click="deleteHelpDoc"
            >
              删除文档
            </el-button>
          </template>
        </AppTable>
      </div>
    </el-card>
    <editHelpDocDialog @on-submit="onSubmit" ref="editHelpDocDialog"></editHelpDocDialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less">
</style>
<script type="text/ecmascript-6">
import editHelpDocDialog from 'components/helpCenter/editHelpDocDialog.vue'

export default {
  data () {
    return {
      multipleSelection: [],
      tableColumns: [
        {
          label: '#',
          type: 'selection'
        },
        {
          label: '标题',
          prop: 'title',
          search: true
        },
        {
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ]
    }
  },
  components: {
    editHelpDocDialog
  },
  methods: {
    onHandleSelectionChange (list) {
      this.multipleSelection = list
    },
    createHelpDoc () {
      this.$refs.editHelpDocDialog.show()
    },
    deleteHelpDoc () {
      this.$api.deleteBatch('helpDoc', this.multipleSelection.map(item => item.id)).then(() => {
        this.$refs.AppTable.clearSelection()
        this.$refs.AppTable.getData()
      })
    },
    onSubmit () {
      this.$refs.AppTable.clearSelection()
      this.$refs.AppTable.getData()
    },
    showHelpDoc (item) {
      // 新标签页打开一个地址
      window.open(item.content)
    },
    editHelpDoc (item) {
      this.$refs.editHelpDocDialog.edit(item)
    }
  },
  mounted () {
  }
}
</script>
